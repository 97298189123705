import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../images/home1.jpg"



const IndexPage = () => {
  //javascript
  const title = 'The Bridge Database';
  const metatitle = 'The Bridge Database';
  const description = 'Meta Description for The Bridge Database';
  const metadescription = description;
  
  return(
    <Layout bodyclass="home" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <img className="homeimage" src={Image} />
      <div className="homeright">
        <h2>Welcome to the Bridge Database.</h2>
        <p><strong>Background</strong>: Bridges are usually the most critical and vulnerable component of road and rail transport systems, while bridge damage due to earthquake results in substantial direct and indirect losses. Therefore, the development of a tool for rapid evaluation of bridge seismic performance for different levels of earthquake intensity is valuable for resilience assessment of road and rail networks and bridge retrofit prioritization. The key requirements for an effective vulnerability assessment tool are the broad application range and the acceptable accuracy in the estimation of structure-specific parameters.</p>
        <p><strong>What is the Bridge Database</strong>: This online database was developed for fragility analysis of as-built and retrofitted bridges and is organised in two ‘tracks’, i.e. fragility curves for a specific bridge can be calculated online based on either of the procedures below:</p>
        <ul>
          <li>literature recommendations and data available in the database, or</li>
          <li>according to a recently proposed, component-based, structure-specific methodology (Stefanidou and Kappos, 2017), using the software developed and included in the Bridge Database.</li>
        </ul>
        <p>In the component-based methodology fragility curves for bridge piers, bearings, and abutments, are developed and combined to derive system fragility. The estimation of component capacity and demand, along with the fragility framework and uncertainty treatment are incorporated into the software that is available <a href="https://thebridgedatabase.com/bridge-specific/vulnerability-curves/">here</a>.</p>
        <ul>
          <li>The first software module refers to bridge pier capacity estimation and limit state threshold quantification and is based on analysis results of a fully parameterised pier model, accounting for inelastic behaviour and different failure modes. Furthermore, experimental data is included in the database, and can be used for deriving user-defined limit state thresholds.</li>
          <li>The second software module allows the calculation of bridge-specific fragility curves, based on dynamic analysis results of a fully parameterised OpenSees model and the application of the probabilistic framework taking account of all pertinent uncertainty sources.</li>
        </ul>
        <p>Both software modules are extended for application to fragility assessment of retrofitted/upgraded bridge components and systems.</p>
      </div>

      
    </Layout>
  )
}

export default IndexPage
